import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

const RadioContainer = styled(Box)({
  display: 'flex',
  gap: '15px',
  marginBottom: '20px',
  flexDirection: 'column',
  '@media (min-width: 600px)': {
    flexDirection: 'row'
  }
});

const RadioButton = styled.label(({ checked }) => ({
  flex: 1,
  padding: '15px',
  border: '2px solid #E8FC6C',
  cursor: 'pointer',
  textAlign: 'center',
  backgroundColor: checked ? '#E8FC6C' : 'transparent',
  color: checked ? '#000000' : '#E8FC6C',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: checked ? '#E8FC6C' : 'rgba(232, 252, 108, 0.1)',
  },
  minHeight: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const HiddenRadio = styled.input({
  display: 'none',
});

const CustomRadioGroup = ({ value, onChange }) => {
  const options = [
    { value: 'beschwerde', label: 'Beschwerde' },
    { value: 'lob', label: 'Lob' },
    { value: 'verbesserung', label: 'Verbesserungsvorschlag' },
  ];

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="body1" sx={{ mb: 2, color: '#E8FC6C' }}>
        Art des Feedbacks
      </Typography>
      <RadioContainer>
        {options.map((option) => (
          <RadioButton 
            key={option.value} 
            checked={value === option.value}
          >
            <HiddenRadio
              type="radio"
              name="feedbackType"
              value={option.value}
              checked={value === option.value}
              onChange={(e) => onChange(e.target.value)}
            />
            {option.label}
          </RadioButton>
        ))}
      </RadioContainer>
    </Box>
  );
};

export default CustomRadioGroup; 