import React, { useState, useCallback } from 'react';
import { 
  Box, 
  Button, 
  TextField, 
  Switch, 
  FormControlLabel, 
  Alert,
  Typography,
  Paper,
  IconButton
} from '@mui/material';
import { AttachFile, Close } from '@mui/icons-material';
import CustomRadioGroup from './CustomRadioGroup';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    message: '',
    isAnonymous: false,
    name: '',
    image: null,
    feedbackType: 'verbesserung'
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [fileName, setFileName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const data = new FormData();
      data.append('message', formData.message);
      data.append('isAnonymous', formData.isAnonymous);
      data.append('feedbackType', formData.feedbackType);
      if (!formData.isAnonymous) {
        data.append('name', formData.name);
      }
      if (formData.image) {
        data.append('image', formData.image);
      }

      const response = await fetch('/src/feedback', {
        method: 'POST',
        body: data
      });

      if (response.ok) {
        setStatus({ type: 'success', message: 'Feedback erfolgreich gesendet!' });
        setFormData({ 
          message: '', 
          isAnonymous: false, 
          name: '', 
          image: null, 
          feedbackType: 'verbesserung' 
        });
        setFileName('');
      } else {
        throw new Error('Fehler beim Senden');
      }
    } catch (error) {
      setStatus({ type: 'error', message: 'Fehler beim Senden des Feedbacks' });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
      setFileName(file.name);
    }
  };

  const removeFile = () => {
    setFormData({ ...formData, image: null });
    setFileName('');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Typography variant="body1" sx={{ mb: 3, color: '#ffffff' }}>
        Hier kannst du deine Anliegen, Vorschläge oder dein Feedback einreichen. 
        Alle deine Eingaben werden vertraulich behandelt.
      </Typography>

      <CustomRadioGroup
        value={formData.feedbackType}
        onChange={(value) => setFormData({ ...formData, feedbackType: value })}
      />

      <TextField
        fullWidth
        multiline
        rows={4}
        label="Ihre Nachricht"
        value={formData.message}
        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
        margin="normal"
        required
        sx={{ mb: 3 }}
      />

      <FormControlLabel
        control={
          <Switch
            checked={formData.isAnonymous}
            onChange={(e) => setFormData({ ...formData, isAnonymous: e.target.checked })}
          />
        }
        label="Anonym einreichen"
        sx={{ 
          mb: 2,
          color: '#ffffff',
          '& .MuiSwitch-track': {
            backgroundColor: '#E8FC6C'
          }
        }}
      />

      {!formData.isAnonymous && (
        <TextField
          fullWidth
          label="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          margin="normal"
          sx={{ mb: 3 }}
        />
      )}

      <Box sx={{ mb: 3 }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-input"
        />
        <label htmlFor="file-input">
          <Button
            component="span"
            variant="outlined"
            startIcon={<AttachFile sx={{ color: '#000000' }} />}
            sx={{ 
              mr: 2,
              color: '#000000',
              backgroundColor: '#E8FC6C',
              borderColor: '#E8FC6C',
              '&:hover': {
                backgroundColor: '#d9eb63',
                borderColor: '#d9eb63',
                color: '#000000',
              }
            }}
          >
            Bild anhängen
          </Button>
        </label>
        {fileName && (
          <Paper
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              px: 2,
              py: 1,
              ml: 1,
              backgroundColor: '#E8FC6C',
              color: '#000000'
            }}
          >
            <Typography variant="body2" sx={{ mr: 1, color: '#000000' }}>
              {fileName}
            </Typography>
            <IconButton 
              size="small" 
              onClick={removeFile}
              sx={{ color: '#000000' }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Paper>
        )}
      </Box>

      <Button
        type="submit"
        variant="contained"
        size="large"
        sx={{ 
          mt: 2,
          width: '100%',
          backgroundColor: '#E8FC6C',
          color: '#000000',
          '&:hover': {
            backgroundColor: '#d9eb63',
            color: '#000000',
          }
        }}
      >
        Feedback senden
      </Button>

      {status.message && (
        <Alert 
          severity={status.type} 
          sx={{ 
            mt: 3,
            borderRadius: 0
          }}
        >
          {status.message}
        </Alert>
      )}
    </Box>
  );
};

export default FeedbackForm; 