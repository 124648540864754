import React from 'react';
import { Container, CssBaseline, ThemeProvider, createTheme, Box, Typography } from '@mui/material';
import FeedbackForm from './components/FeedbackForm';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E8FC6C',
      contrastText: '#000000',
    },
    secondary: {
      main: '#000000',
      contrastText: '#E8FC6C',
    },
    background: {
      default: '#000000',
      paper: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: '#E8FC6C',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: '12px 30px',
          textTransform: 'none',
          fontWeight: 500,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            color: '#ffffff',
            '& fieldset': {
              borderColor: '#E8FC6C',
            },
            '&:hover fieldset': {
              borderColor: '#E8FC6C',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#E8FC6C',
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{ 
          bgcolor: 'black',
          borderBottom: '1px solid #E8FC6C',
          py: 2,
          mb: 4
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2 
          }}>
            <img 
              src="/assets/qp-logo.png"
              alt="QUADRAT P RAUM UND LICHT" 
              style={{ 
                height: '60px',
                marginRight: '20px',
                objectFit: 'contain'
              }} 
            />
            <Typography 
              variant="h5" 
              sx={{ 
                color: '#E8FC6C',
                fontWeight: 'bold'
              }}
            >
              Feedback
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box sx={{ 
          backgroundColor: 'black', 
          p: 4,
          border: '1px solid #E8FC6C'
        }}>
          <Typography 
            variant="h4" 
            sx={{ 
              color: '#E8FC6C',
              mb: 4 
            }}
          >
            Deine Meinung zählt – Gestalte mit uns eine bessere Zukunft! 🌟
          </Typography>

          <Typography 
            variant="body1" 
            sx={{ 
              color: '#ffffff',
              mb: 3,
              lineHeight: 1.6
            }}
          >
            Wir möchten uns stetig verbessern – für dich und dein Arbeitsumfeld! 🎯 Ob es um langwierige Prozesse, unklare Abläufe, fehlende Tools oder andere Herausforderungen in deinem Arbeitsalltag geht: Dein Feedback hilft uns, diese Hürden zu erkennen und gemeinsam Lösungen zu finden. 💪
          </Typography>

          <Typography 
            variant="body1" 
            sx={{ 
              color: '#ffffff',
              mb: 3,
              lineHeight: 1.6
            }}
          >
            Vielleicht stört dich ein unnötig komplizierter Ablauf? 🤔 Oder du hast eine Idee, wie wir unsere Kommunikation oder Zusammenarbeit noch effizienter gestalten können? ✨ Egal ob kleine Unannehmlichkeiten oder große Verbesserungsvorschläge – wir möchten deine Gedanken dazu hören! 📝
          </Typography>

          <Typography 
            variant="body1" 
            sx={{ 
              color: '#ffffff',
              mb: 4,
              lineHeight: 1.6
            }}
          >
            Deine Meinung ist der Schlüssel zu besseren Prozessen und einem Arbeitsumfeld, in dem du dich wohlfühlst. 🔑 Teil dein Feedback, anonym oder mit deinem Namen, und gestalte aktiv unsere Zukunft mit! 💡✨
          </Typography>

          <FeedbackForm />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App; 